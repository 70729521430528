<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="10" style="justify-content: center; display: flex">
        <div
          :style="user.roleName != 'supplier' ? 'width: 40%;' : 'width: 100%;'"
        >
          <v-card
            v-if="accessUser.userType !== 'account_manager'"
            :class="
              user.roleName != 'supplier'
                ? 'profile-container-card'
                : 'supplier-profile-container-card'
            "
          >
            <v-col cols="12" md="12">
              <v-card-text class="text-left">
                <h2 class="title_style ml-3">Personal Information</h2>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="12">
              <v-avatar
                size="150"
                v-if="!preview"
                :class="
                  user.profileImage
                    ? 'profile_image_style'
                    : 'default_image_style'
                "
              >
                <v-img :src="user.imageUrl" />
              </v-avatar>
            </v-col>
            <v-col cols="12" md="12" class="text-center pt-0">
              <p class="name_style" data-cy="full-name">
                {{ user.firstName }} {{ user.lastName }}
              </p>
              <div class="mt-2" v-if="user.brandName">
                <span class="subtitle_data"> Business Name: </span>
                <span class="subtitle_data">
                  {{ user.brandName }}
                </span>
              </div>
              <!-- <p
                class="subtitle_data"
              >
                {{ form.dateOfBirth }}
              </p> -->
            </v-col>
          </v-card>
          <v-card
            v-if="accessUser.userType === 'account_manager'"
            :class="
              user.roleName != 'supplier'
                ? 'profile-container-card'
                : 'supplier-profile-container-card'
            "
          >
            <v-col cols="12" md="12">
              <v-card-text class="text-left">
                <h2 class="title_style ml-3">Personal Information</h2>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="12">
              <v-avatar
                size="150"
                v-if="!preview"
                :class="
                  user.profileImage
                    ? 'profile_image_style'
                    : 'default_image_style'
                "
              >
                <v-img :src="user.imageUrl" />
              </v-avatar>
            </v-col>
            <v-col cols="12" md="12" class="text-center pt-0">
              <p class="name_style" data-cy="full-name">
                {{ user.firstName }} {{ user.lastName }}
              </p>
              <p class="subtitle_data">
                Account Type: {{ getUserType(user.roleName) }}
              </p>
              <!-- <p
                class="subtitle_data"
              >
                {{ form.dateOfBirth }}
              </p> -->
            </v-col>
            <v-col cols="12">
              <v-divider justify="center" class="divider_style" />
            </v-col>
            <v-col md="11" class="text-left ml-7 mr-7">
              <p class="subtitle_style mb-6">Contact Information</p>

              <div class="mb-5">
                <span class="subtitle_data" v-if="user.userType === 'customer'">
                  Business Name:
                </span>
                <span class="subtitle_data" v-if="user.userType === 'customer'">
                  {{ user.brandName }}
                </span>
              </div>
              <div v-if="user.email" class="mb-5">
                <span class="subtitle_data"> Email Address: </span>
                <span class="subtitle_data ml-1">
                  {{ user.email }}
                </span>
              </div>
              <div v-if="user.phoneNumber" class="mb-5">
                <span class="subtitle_data"> Phone Number: </span>
                <span class="subtitle_data font-weight-bold ml-1 mb-4">
                  {{ user.phoneNumber }}
                </span>
              </div>
              <p class="subtitle_data" v-if="user.billingAddressLine1">
                Billing Address:
              </p>

              <p class="subtitle_data" v-if="user.billingAddressLine1">
                {{ user.billingAddressLine1 }} <br />
                {{ user.billingAddressLine2 }}
                <br v-if="user.billingAddressLine2" />
                {{ user.billingAddressCity }} <br />
                {{ user.billingAddressState }}
                <br v-if="user.billingAddressState" />
                {{ user.billingAddressPostal }} <br />
                {{ user.billingAddressCountry }}
              </p>

              <p class="subtitle_data" v-if="user.deliveryAddressLine1">
                Delivery Address:
              </p>
              <p class="subtitle_data" v-if="user.deliveryAddressLine1">
                {{ user.deliveryAddressLine1 }} <br />
                {{ user.deliveryAddressLine2 }}
                <br v-if="user.deliveryAddressLine2" />
                {{ user.deliveryAddressCity }} <br />
                {{ user.deliveryAddressState }}
                <br v-if="user.deliveryAddressLineState" />
                {{ user.deliveryAddressPostal }} <br />
                {{ user.deliveryAddressCountry }}
              </p>
            </v-col>
          </v-card>

          <v-card v-if="user.isCompany" class="data-container-card">
            <v-row style="padding-left: 8px">
              <v-col cols="12" md="12">
                <v-card-text class="text-left">
                  <p class="title_style ml-4">Company Overview</p>
                </v-card-text>
              </v-col>
              <v-col cols="12">
                <v-divider class="company_overview_divider_style" />
              </v-col>
              <v-row>
                <v-col cols="6" style="margin-top: -18px">
                  <v-col
                    cols="12"
                    class="text-left ml-5"
                    style="line-height: 40px"
                  >
                    <div v-if="user.brandName">
                      <span class="subtitle_data ml-3"> Name: </span>
                      <span class="subtitle_data font-weight-bold">
                        {{ user.brandName }}
                      </span>
                    </div>
                    <div v-if="company.websiteUrl">
                      <span class="subtitle_data ml-3"> Website: </span>
                      <span class="subtitle_data font-weight-bold">
                        {{ company.websiteUrl }}
                      </span>
                    </div>
                    <div v-if="company.registrationNumber">
                      <span class="subtitle_data ml-3">
                        Registation Number:
                      </span>
                      <span class="subtitle_data font-weight-bold">
                        {{ company.registrationNumber }}
                      </span>
                    </div>
                    <div
                      v-if="
                        company.establishedCountry && company.establishedYear
                      "
                    >
                      <span class="subtitle_data ml-3"> Established: </span>
                      <span class="subtitle_data">
                        {{ company.establishedCountry }},
                        {{ company.establishedYear }}
                      </span>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="text-left ml-8"
                    v-if="company.companyMission"
                  >
                    <p class="subtitle_data">Company Mission:</p>
                    <p class="paragraph_data">
                      {{ company.companyMission }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="text-left ml-8"
                    v-if="company.companyVision"
                  >
                    <p class="subtitle_data">Company Vision:</p>
                    <p class="paragraph_data">
                      {{ company.companyVision }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="text-left ml-8"
                    v-if="company.valueAddedServices"
                  >
                    <p class="subtitle_data">Value Added Services:</p>
                    <p class="paragraph_data">
                      {{ company.valueAddedServices }}
                    </p>
                  </v-col>
                </v-col>
                <v-col cols="6" v-if="company.productTypes.length">
                  <div class="products">
                    <div class="subtitle_data data-margin">Products:</div>
                    <div
                      class="subtitle_data font-weight-bold data-margin"
                      v-for="selected in company.productTypes"
                      :key="selected"
                    >
                      {{ getProductTypeName(selected) }}
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-col
                cols="12"
                v-if="
                  company.employeeCount ||
                  company.machineCount ||
                  company.productionCapacity
                "
              >
                <div class="more-info-style mt-2">
                  <div class="md-layout-item" v-if="company.employeeCount">
                    <span class="subtitle_data"> Employees: </span>
                    <span class="subtitle_data ml-1">
                      {{ company.employeeCount }}
                    </span>
                  </div>
                  <v-divider
                    v-if="company.employeeCount"
                    vertical
                    class="vertical-hr"
                  />
                  <div class="md-layout-item" v-if="company.machineCount">
                    <span class="subtitle_data"> Machines: </span>
                    <span class="subtitle_data ml-1">
                      {{ company.machineCount }}
                    </span>
                  </div>
                  <v-divider
                    vertical
                    class="vertical-hr"
                    v-if="company.machineCount"
                  />
                  <div class="md-layout-item" v-if="company.productionCapacity">
                    <span class="subtitle_data"> Production Capacity: </span>
                    <span class="subtitle_data ml-1">
                      {{ company.productionCapacity }}
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                v-if="company.sampleLeadTime || company.productionLeadTime"
              >
                <div class="more-info-style">
                  <div class="md-layout-item" v-if="company.sampleLeadTime">
                    <span class="subtitle_data"> Sample Lead Time: </span>
                    <span class="subtitle_data ml-1">
                      {{ company.sampleLeadTime }}
                      <span
                        class="font-weight-bold"
                        v-if="company.sampleLeadTime == 1"
                      >
                        Week
                      </span>
                      <span class="font-weight-bold" v-else>Weeks</span>
                    </span>
                  </div>
                  <v-divider
                    vertical
                    class="vertical-hr"
                    v-if="company.sampleLeadTime && company.productionLeadTime"
                  />
                  <div class="md-layout-item" v-if="company.productionLeadTime">
                    <span class="subtitle_data"> Production Lead Time: </span>
                    <span class="subtitle_data ml-1">
                      {{ company.productionLeadTime }}
                      <span
                        class="font-weight-bold"
                        v-if="company.productionLeadTime == 1"
                      >
                        Week
                      </span>
                      <span class="font-weight-bold" v-else>Weeks</span>
                    </span>
                  </div>
                </div>
              </v-col>
              <!-- <v-col
                cols="12"
                md="12"
                v-if="company.productionLeadTime || company.sampleLeadTime"
              >
                <v-divider />
              </v-col> -->
              <v-col
                cols="12"
                md="5"
                class="text-left ml-8 mt-3"
                v-if="company.sampleDevelopmentCondition"
              >
                <p class="subtitle_data">Sample Development Condition</p>
                <p class="paragraph_data">
                  {{ company.sampleDevelopmentCondition }}
                </p>
              </v-col>
              <v-col
                cols="12"
                md="5"
                class="text-left ml-8 mt-3"
                v-if="company.bulkManufacturingCondition"
              >
                <p class="subtitle_data">Bulk Manufacturing Condition</p>
                <p class="paragraph_data">
                  {{ company.bulkManufacturingCondition }}
                </p>
              </v-col>
            </v-row>
          </v-card>

          <v-row class="mb-7">
            <v-col
              cols="6"
              v-if="
                accessUser.userType === 'account_manager' &&
                contactDetails.length > 0
              "
            >
              <v-card class="contact-container-card">
                <v-row style="padding-left: 8px">
                  <v-col cols="12" md="6">
                    <v-card-text class="text-left ml-4">
                      <h2 class="title_style">Contact Details</h2>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12">
                    <v-divider class="company_overview_divider_style" />
                  </v-col>
                  <v-col
                    md="12"
                    class="text-left ml-8 mb-4"
                    v-for="contact in contactDetails"
                    :key="contact.id"
                  >
                    <div>
                      <span class="subtitle_data"> Name: </span>
                      <span class="subtitle_data ml-1">
                        {{ contact.name }}
                      </span>
                    </div>
                    <div class="mt-2">
                      <span class="subtitle_data"> Title: </span>
                      <span class="subtitle_data ml-1">
                        {{ contact.designation }}
                      </span>
                    </div>
                    <div class="mt-2">
                      <span class="subtitle_data"> Email Address: </span>
                      <span class="subtitle_data ml-1">
                        {{ contact.email }}
                      </span>
                    </div>
                    <div class="mt-2">
                      <span class="subtitle_data"> Phone Number: </span>
                      <span class="subtitle_data font-weight-bold ml-1">
                        {{ contact.mobile_number }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card
                v-if="machineDetails.length > 0"
                class="machines-container-card"
              >
                <v-row style="padding-left: 8px">
                  <v-col cols="12" md="12">
                    <v-card-text class="text-left">
                      <h2 class="title_style ml-4">Machines</h2>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-divider
                      justify="center"
                      class="company_overview_divider_style"
                    />
                  </v-col>
                  <v-row class="machines-data-container">
                    <v-col cols="12">
                      <div class="machine-card-style">
                        <div
                          class="md-layout-item machine-type subtitle_data font-weight-bold"
                        >
                          Machine Type
                        </div>
                        <v-divider vertical class="vertical-hr" />
                        <div
                          class="md-layout-item amount subtitle_data font-weight-bold"
                        >
                          Amount
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="machines-data-container mt-0">
                    <v-col
                      cols="12"
                      v-for="machine in machineDetails"
                      :key="machine.id"
                      class="machines-container-alignment"
                    >
                      <div class="machine-card-style">
                        <div class="md-layout-item machine-type subtitle_data">
                          <div>{{ machine.machine_type }}</div>
                        </div>
                        <v-divider vertical class="vertical-hr" />
                        <div class="md-layout-item amount subtitle_data">
                          <div>{{ machine.number_of_machines }}</div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="allImages.length > 0" style="margin-top: -25px">
            <v-col cols="12" md="12" class="mt-7">
              <SupplierProfileImage
                imageCategory="Company Gallery"
                :companyImages="companyGalleryImages"
                :baseUrl="baseUrl"
                @get-users="getUser"
                :showAddBtn="false"
                :showOptionBtn="false"
              />
              <SupplierProfileImage
                imageCategory="Products"
                :companyImages="productImages"
                :baseUrl="baseUrl"
                @get-users="getUser"
                :showAddBtn="false"
                :showOptionBtn="false"
              />
              <SupplierProfileImage
                imageCategory="Certifications"
                :companyImages="certificationImages"
                :baseUrl="baseUrl"
                @get-users="getUser"
                :showAddBtn="false"
                :showOptionBtn="false"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from "../../auth";
import notification from "../../notification";
import restAdapter from "../../restAdapter";
import * as conf from "../../conf.yml";
import utils from "../../utils";
import defaultImage from "../../assets/person.webp";
import SupplierProfileImage from "../../views/dashboard/pages/SupplierProfileImages.vue";
/* eslint-disable */
export default {
  name: "BusinessProfile",
  components: { SupplierProfileImage },
  data() {
    return {
      baseUrl: "",
      productTypes: [],
      roles: [],
      acceptedFileTypes: utils.acceptedFileTypes,
      accessUser: {},
      preview: false,
      user: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        brandName: null,
        imageUrl: defaultImage,
        isCompany: null,
        billingAddressLine1: null,
        billingAddressLine2: null,
        billingAddressCity: null,
        billingAddressState: null,
        billingAddressPostal: null,
        billingAddressCountry: null,
        deliveryAddressLine1: null,
        deliveryAddressLine2: null,
        deliveryAddressCity: null,
        deliveryAddressState: null,
        deliveryAddressPostal: null,
        deliveryAddressCountry: null,
        profileImage: false,
        roleName: null,
      },
      company: {
        registrationNumber: null,
        websiteUrl: null,
        establishedYear: null,
        establishedCountry: null,
        valueAddedServices: null,
        companyVision: null,
        compnayMission: null,
        productTypes: [],
        roles: [],
        employeeCount: null,
        machineCount: null,
        productionCapacity: null,
        sampleLeadTime: null,
        productionLeadTime: null,
        sampleDevelopmentCondition: null,
        bulkManufacturingCondition: null,
      },
      contactDetails: [],
      machineDetails: [],
      allImages: [],
      companyGalleryImages: [],
      productImages: [],
      certificationImages: [],
    };
  },
  methods: {
    getUser() {
      restAdapter
        .post("/api/user/display_user_profile/" + this.userId, {
          preview: true,
        })
        .then((res) => {
          this.user.firstName = res.data.first_name;
          this.user.lastName = res.data.last_name;
          this.user.brandName = res.data.brand_name;
          this.user.phoneNumber = res.data.phone_number;
          this.user.email = res.data.email;
          this.user.isCompany = res.data.isCompany;
          this.user.roleName = res.data.role_name;

          if (this.accessUser.userType === "account_manager") {
            this.user.billingAddressLine1 = res.data.billing_address_line1;

            this.user.billingAddressLine2 = res.data.billing_address_line2;

            this.user.billingAddressCity = res.data.billing_address_city;

            this.user.billingAddressState = res.data.billing_address_state;

            this.user.billingAddressPostal = res.data.billing_address_post_code;

            this.user.billingAddressCountry = res.data.billing_address_country;

            this.user.deliveryAddressLine1 = res.data.delivery_address_line1;

            this.user.deliveryAddressLine2 = res.data.delivery_address_line2;

            this.user.deliveryAddressCity = res.data.delivery_address_city;

            this.user.deliveryAddressState = res.data.delivery_address_state;

            this.user.deliveryAddressPostal =
              res.data.delivery_address_post_code;

            this.user.deliveryAddressCountry =
              res.data.delivery_address_country;
          }
          if (res.data.avatar != "users/default.png") {
            restAdapter
              .getWithOutCache("/api/user/image_show/" + this.userId)
              .then((res) => {
                this.user.profileImage = true;
                this.user.imageUrl = res.data.image;
              });
          }
          for (let i = 0; i < res.data.product_types.length; i++) {
            this.company.productTypes.push(
              res.data.product_types[i].product_id
            );
          }
          for (let i = 0; i < res.data.gallery.length; i++)
            this.allImages.push(res.data.gallery[i]);

          if (res.data.company) {
            if (res.data.company.business_registration_number != null)
              this.company.registrationNumber =
                res.data.company.business_registration_number;

            if (res.data.company.website_url != null)
              this.company.websiteUrl = res.data.company.website_url;

            if (res.data.company.established_year != null)
              this.company.establishedYear = res.data.company.established_year;

            if (res.data.company.established_country != null)
              this.company.establishedCountry =
                res.data.company.established_country;
            if (res.data.company.value_added_services)
              this.company.valueAddedServices =
                res.data.company.value_added_services;

            if (res.data.company.mission != null)
              this.company.companyMission = res.data.company.mission;

            if (res.data.company.vision != null)
              this.company.companyVision = res.data.company.vision;

            if (res.data.company.number_of_employees != null)
              this.company.employeeCount = res.data.company.number_of_employees;

            if (res.data.company.number_of_machines != null)
              this.company.machineCount = res.data.company.number_of_machines;

            if (res.data.company.production_capacity != null)
              this.company.productionCapacity =
                res.data.company.production_capacity;

            if (res.data.company.sample_lead_time != null)
              this.company.sampleLeadTime = res.data.company.sample_lead_time;

            if (res.data.company.production_lead_time != null)
              this.company.productionLeadTime =
                res.data.company.production_lead_time;

            if (res.data.company.sample_development_condition != null)
              this.company.sampleDevelopmentCondition =
                res.data.company.sample_development_condition;

            if (res.data.company.bulk_manufacturing_condition != null)
              this.company.bulkManufacturingCondition =
                res.data.company.bulk_manufacturing_condition;
          }

          for (let i = 0; i < res.data.machine_types.length; i++) {
            this.machineDetails.push(res.data.machine_types[i]);
          }

          this.companyGalleryImages = this.allImages.filter(function (u) {
            return u.file_type == 1;
          });
          this.productImages = this.allImages.filter(function (u) {
            return u.file_type == 2;
          });
          this.certificationImages = this.allImages.filter(function (u) {
            return u.file_type == 3;
          });
          if (res.data.contact_details) {
            for (let i = 0; i < res.data.contact_details.length; i++) {
              this.contactDetails.push(res.data.contact_details[i]);
            }
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    getRoleName(id) {
      const role = this.roles.find((role) => role.id == id);
      return role && role.display_name;
    },
    getProductTypeName(id) {
      const productType = this.productTypes.find(
        (productType) => productType.id == id
      );
      return productType && productType.name;
    },
    getProductTypes() {
      return restAdapter.get("/api/inquire_product_types");
    },
    getServices() {
      return restAdapter.get("/api/inquire_services");
    },
    getRoles() {
      return restAdapter.get("/api/login/display_supplier_role");
    },
    isSupportedFileType(filename) {
      const extension =
        filename.match(/\.[0-9a-z]+$/i) && filename.match(/\.[0-9a-z]+$/i)[0];
      const supportedFileTypes = this.acceptedFileTypes.split(",");
      return supportedFileTypes.includes(extension);
    },
    getUserType(type) {
      switch (type) {
        case "account_manager":
          return "Account Manager";
        case "customer":
          return "Buyer";
        case "supplier":
          return "Supplier";
        default:
          return type;
      }
    },
  },
  mounted() {
    this.accessUser = { ...auth.user };
    this.userId = this.$route.params.id;
    this.baseUrl = conf.default.server.host;
    this.getUser();
    this.getRoles().then((response) => {
      this.roles = response.data;
    });
    this.getProductTypes().then((response) => {
      this.productTypes = response.data.productTypes;
    });
  },
};
</script>
<style lang="scss" scoped>
.password-policy {
  padding-bottom: 12px;
  text-align: left;
}

.password-policy__items {
  padding-left: 20px;
}
.centered-and-cropped {
  object-fit: cover;
  height: 100% !important;
  width: 100%;
}
.embed {
  height: 200px;
}

.profile-container-card {
  background: #faf7ff !important;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border: 1px solid #473068 !important;
  border-radius: 7px !important;
  padding-bottom: 30px;
}

.supplier-profile-container-card {
  background: #ffffff !important;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  padding-bottom: 30px;
}

.contact-container-card {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  padding-bottom: 30px;
  height: 100%;
}

.data-container-card {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  padding-bottom: 30px;
}

.machines-container-card {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgb(71 48 104 / 6%) !important;
  border-radius: 7px !important;
  padding-bottom: 60px;
  height: 100%;
}

.title_style {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #473068;
  text-decoration-line: underline;
  text-align: left;
}

.profile_image_style {
  border: unset;
  box-shadow: unset;
}

.default_image_style {
  border: 7px solid #ffffff;
  box-shadow: 0px 6.5045px 4.8836px 1px rgb(61 77 135 / 10%);
}

.name_style {
  color: #473068;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin: 12px 0px 0px 0px;
}

.products {
  background-color: #f6f1ff;
  width: auto;
  height: auto;
  border-radius: 3px;
  text-align: left;
  padding: 22px 30px;
  margin: -12px 38px;
}

.subtitle_data {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #473068;
}

.paragraph_data {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #473068;
}

.divider_style {
  border-color: #442d65 !important;
  margin: 25px 25px 15px 25px;
}

.company_overview_divider_style {
  border-color: #442d65 !important;
  margin: -15px 25px 15px 32px;
}

.vertical-hr {
  align-self: center;
  border-color: #442d65 !important;
  max-height: 60%;
  min-height: 60%;
}

.subtitle_style {
  color: #473068;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.data-margin {
  line-height: 30px;
}

.more-info-style {
  background: rgba(112, 208, 206, 0.18);
  border-radius: 3px;
  margin-right: 10px;
  width: 80%;
  height: 52px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 32px;
}

.machine-card-style {
  margin-right: 10px;
  max-width: 410px;
  height: 52px;
  border-radius: 3px;
  border: 0.5px solid #eef1fa;
  background: #eef1fa;
  display: flex;
  align-items: center;
  text-align: left;
}

.machine-type {
  margin: 0px 100px 0px 32px;
}

.amount {
  display: flex;
  justify-content: center;
  align-items: center;
}

.machines-data-container {
  padding: 0px 50px;
  margin: 5px 0px -8px -16px;
}

.machines-container-alignment {
  margin: -8px 0px;
}
</style>
